import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/stock/cycle/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;
            console.log('add Data',data);
            return new Promise((resolve, reject) => {
                axios
                  .post('/stock/cycle/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;
            console.log('update Data',data)
            return new Promise((resolve, reject) => {
                axios
                  .put(`/stock/cycle/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/stock/cycle/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
    }
}